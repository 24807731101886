import React, { useMemo } from 'react'
import { OrderStatus } from '../../types'

interface Props {
  status: OrderStatus
  submittedFormDate: string | null
}

const SubmittedFormDataCell = ({ status, submittedFormDate }: Props): JSX.Element => {
  const backgroundColor = useMemo(() => {
    if (status === 'in_transport') {
      return ''
    }
    if (submittedFormDate === null) {
      return ''
    }
    const [day, month, year] = submittedFormDate.split('.').map(Number)
    const date = new Date(year, month - 1, day)
    const now = new Date()
    const diffTime = now.getTime() - date.getTime()
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))
    if (diffDays < 6) {
      return '#d8f3dc'
    }
    if (diffDays < 10) {
      return '#ffbf69'
    }
    if (diffDays >= 10) {
      return '#ee6055'
    }
    return ''
  }, [submittedFormDate, status])

  return (
    <td style={{ backgroundColor }} className={'p-2 border border-[#f4f4f4]'}>
      {submittedFormDate}
    </td>
  )
}

export default SubmittedFormDataCell
