import React from 'react'
import { Form, Field } from 'react-final-form'
import { CreateInvoicePayload } from 'src/redux/invoiceReducer'
import InvoiceInput from '../atoms/InvoiceInput'
import CustomerDataFrom from './CustomerDataFrom'

interface Props {
  initialFormValues: CreateInvoicePayload
  onSubmit: (values: CreateInvoicePayload) => void
  isLoading: boolean
  withMakePaymentField?: boolean
  type?: 'new' | 'edit'
}

const InvoiceForm = ({
  initialFormValues,
  onSubmit,
  isLoading,
  type = 'new',
  withMakePaymentField = false
}: Props): JSX.Element => {
  const required = (value: string | undefined): string | undefined => {
    // eslint-disable-next-line no-undefined, @typescript-eslint/strict-boolean-expressions
    return value ? undefined : 'Required'
  }

  return (
    <Form
      initialValues={initialFormValues}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <form onSubmit={handleSubmit}>
          <div>Rechnungspositionen Daten</div>
          <div className="h-3" />
          <div className="flex flex-wrap max-w-[40rem] gap-5">
            <div>
              <label className="text-16 block">Price gross</label>
              <Field validate={required} name="price_gross" component={InvoiceInput} inputType="number" />
            </div>
            {withMakePaymentField && (
              <div>
                <label className="text-16 block">Treuhand</label>
                <Field name="make_payment_gross" component={InvoiceInput} inputType="number" />
              </div>
            )}
            <div>
              <label className="text-16 block">Zip from</label>
              <Field validate={required} name="zip_from" component={InvoiceInput} inputType="number" />
            </div>
            <div>
              <label className="text-16 block">Zip to</label>
              <Field validate={required} name="zip_to" component={InvoiceInput} inputType="number" />
            </div>
            <div className="w-[37.5rem]">
              <label className="text-16 block">Description</label>
              <Field
                validate={required}
                name="description"
                component={InvoiceInput}
                className="w-full"
                inputType="textarea"
              />
            </div>
          </div>
          <div className="h-3" />
          <div className="h-3" />
          <CustomerDataFrom />
          <div className="h-3" />
          <button
            disabled={isLoading}
            type="submit"
            className="bg-orange text-14 py-2 px-10 text-center rounded-md cursor-pointer disabled:cursor-not-allowed">
            {type === 'edit' ? 'Aktualisieren' : 'Erstellen'}
          </button>
        </form>
      )}
    />
  )
}

export default InvoiceForm
