import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { GET } from 'src/consts/methods'
import {
  FETCH_ROUTES_ORDERS,
  FETCH_ROUTES_ORDERS_FAIL,
  FETCH_ROUTES_ORDERS_SUCCESS,
  FetchOrdersAction
} from 'src/redux/routeOrders'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* FetchRouteOrdersFlow(action: FetchOrdersAction): Generator<any, void, any> {
  try {
    const response = yield call(apiCall, {
      endpoint: `/admin/routes/${action.payload.route_id}/route_orders`,
      method: GET,
      params: {}
    })
    yield put(FETCH_ROUTES_ORDERS_SUCCESS({ route_id: action.payload.route_id, orders: response }))
  } catch (error) {
    yield put(FETCH_ROUTES_ORDERS_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* FetchRouteOrdersSaga(): Generator<any, void, any> {
  yield takeLatest(FETCH_ROUTES_ORDERS, FetchRouteOrdersFlow)
}
