import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formatDuration } from 'src/helpers/formatDuration'
import { RootReducer } from 'src/redux/rootReducer'
import { SET_ROUTE_NAME } from 'src/redux/routeReducer'
import { formatDistance } from 'src/helpers/formatDistance'
const RouteTitleInput = (): JSX.Element => {
  const dispatch = useDispatch()
  const title = useSelector((state: RootReducer) => state.routeReducer.routeName)
  const totalDistance = useSelector((state: RootReducer) => {
    if (state.routeReducer.directions !== null && state.routeReducer.directions.routes.length > 0) {
      return state.routeReducer.directions.routes[0].legs.reduce((acc, leg) => acc + (leg.distance?.value ?? 0), 0)
    }
    return 0
  })

  const totalDuration = useSelector((state: RootReducer) => {
    if (state.routeReducer.directions !== null && state.routeReducer.directions.routes.length > 0) {
      return state.routeReducer.directions.routes[0].legs.reduce((acc, leg) => acc + (leg.duration?.value ?? 0), 0)
    }
    return 0
  })

  return (
    <div>
      <input
        type="text"
        value={title}
        className="border-2 border-gray-300 rounded-md p-2"
        onChange={(e) => {
          dispatch(SET_ROUTE_NAME(e.target.value))
        }}
      />
      <div>
        <div>{totalDistance === 0 ? '' : formatDistance(totalDistance)}</div>
        <div>{totalDuration === 0 ? '' : formatDuration(totalDuration)}</div>
      </div>
    </div>
  )
}

export default RouteTitleInput
