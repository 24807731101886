import { put, takeLatest } from 'redux-saga/effects'
import { SIGN_IN, SIGN_IN_FAIL, SIGN_IN_SUCCESS, SignInAction } from '../redux/authReducer'
import { navigate } from 'src/helpers/navgate'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* signInFlow({ payload }: SignInAction): Generator<any, void, any> {
  try {
    const formData = new URLSearchParams()
    formData.append('utf8', '✓')
    formData.append('user[email]', payload.email)
    formData.append('user[password]', payload.password)
    formData.append('commit', 'Log in')
    formData.append('user[remember_me]', '1')

    const response = yield fetch('/users/sign_in', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Referrer-Policy': 'strict-origin-when-cross-origin'
      },
      body: formData.toString()
    })
    if (typeof response.redirected === 'boolean' && Boolean(response.redirected)) {
      yield put(SIGN_IN_SUCCESS())
      navigate('/')
      yield put(SIGN_IN_FAIL())
    }
  } catch (error) {
    yield put(SIGN_IN_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* SignInSaga(): Generator<any, void, any> {
  yield takeLatest(SIGN_IN, signInFlow)
}
