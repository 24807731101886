/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect } from 'react'
import Layout from '../Layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootReducer } from 'src/redux/rootReducer'
import { FETCH_INVOICE } from 'src/redux/invoiceReducer'
import EditInvoiceForm from './EditInvoiceForm'

const EditInvoice = (): JSX.Element => {
  const { id } = useParams()
  const isLoading = useSelector((state: RootReducer) => state.invoiceReducer.isLoading)
  const dispatch = useDispatch()
  const invoiceData = useSelector((state: RootReducer) => state.invoiceReducer.invoices[id ?? ''])
  useEffect(() => {
    dispatch(FETCH_INVOICE({ id: parseInt(id ?? '', 10) }))
  }, [dispatch, id])

  if (typeof invoiceData === 'undefined') {
    return <></>
  }

  return (
    <Layout>
      <div className="font-semibold text-22 p-3">
        <EditInvoiceForm invoice={invoiceData} isLoading={isLoading} />
      </div>
    </Layout>
  )
}

export default EditInvoice
