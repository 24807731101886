/* eslint-disable @typescript-eslint/ban-types */
import { createReducer, createAction, PayloadAction } from '@reduxjs/toolkit'
import { RouteOrder } from '../types'

export interface RouteOrdersReducer {
  orders: Record<string, RouteOrder[]>
}

const initialState: RouteOrdersReducer = {
  orders: {}
}

interface FetchOrdersPayload {
  route_id: string
}
export type FetchOrdersAction = PayloadAction<FetchOrdersPayload>
export const FETCH_ROUTES_ORDERS = createAction<FetchOrdersPayload>('routes_orders_reducer/fetch_routes_orders')
interface FetchOrdersSuccessPayload {
  route_id: string
  orders: RouteOrder[]
}
export type FetchOrdersSuccessAction = PayloadAction<FetchOrdersSuccessPayload>
export const FETCH_ROUTES_ORDERS_SUCCESS = createAction<FetchOrdersSuccessPayload>(
  'routes_orders_reducer/fetch_routes_orders_success'
)
export const FETCH_ROUTES_ORDERS_FAIL = createAction('routes_orders_reducer/fetch_routes_orders_fail')

export const routeOrdersReducer = createReducer(initialState, (builder) => {
  builder.addCase(FETCH_ROUTES_ORDERS_SUCCESS, (state, action) => {
    return { ...state, orders: { ...state.orders, [action.payload.route_id]: action.payload.orders } }
  })
})

export default routeOrdersReducer
