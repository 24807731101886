import React, { useEffect } from 'react'
import Layout from '../Layout/Layout'
import RouteTitleInput from '../atoms/RouteTitleInput'
import OrderButton from '../atoms/OrderButton'
import { faClipboardList, faPrint, faTruck } from '@fortawesome/free-solid-svg-icons'
import RoutePlaningOrderList from '../molecules/RoutePlaningOrderList'
import RouteMap from '../molecules/RouteMap'
import RouteElements from '../molecules/RouteElements'
import { useDispatch, useSelector } from 'react-redux'
import { CREATE_OR_UPDATE_ROUTE, FETCH_DIRECTIONS, FETCH_ROUTE, RESET_ROUTE_REDUCER } from 'src/redux/routeReducer'
import { faSave } from '@fortawesome/free-regular-svg-icons'
import { useParams, useNavigate } from 'react-router-dom'
import { RootReducer } from 'src/redux/rootReducer'

const NewRouteScreen = (): JSX.Element => {
  const { id: routeId } = useParams()
  const dispatch = useDispatch()
  const routePdf = useSelector((state: RootReducer) => state.routeReducer.routePdf)
  const savedWithNotification = useSelector((state: RootReducer) => state.routeReducer.savedWithNotification)
  const navigate = useNavigate()
  useEffect(() => {
    return () => {
      dispatch(RESET_ROUTE_REDUCER())
    }
  }, [dispatch])

  useEffect(() => {
    if (typeof routeId === 'string') {
      dispatch(FETCH_ROUTE({ id: parseInt(routeId, 10) }))
    }
  }, [routeId, dispatch])

  return (
    <Layout>
      <div className="p-3">
        <div className="flex">
          <div>
            <RouteTitleInput />
            <div className="h-5" />
            <RouteElements />
            <div className="h-5" />
            <RoutePlaningOrderList />
          </div>
          <div className="w-5" />
          <div>
            <div className="flex flex-row gap-2">
              <OrderButton
                onClick={() => {
                  dispatch(FETCH_DIRECTIONS())
                }}
                icon={faTruck}
                label="Route berechnen"
              />
              <OrderButton
                onClick={() => {
                  dispatch(
                    CREATE_OR_UPDATE_ROUTE({
                      withNotification: false,
                      withStatusChange: true,
                      routeId: parseInt(routeId ?? '', 10) ?? null
                    })
                  )
                }}
                icon={faSave}
                label="Speichern"
              />
              <OrderButton
                onClick={() => {
                  dispatch(
                    CREATE_OR_UPDATE_ROUTE({
                      withNotification: false,
                      withStatusChange: false,
                      routeId: parseInt(routeId ?? '', 10) ?? null
                    })
                  )
                }}
                icon={faSave}
                label="Speichern ohne Status"
              />
              <OrderButton
                active={savedWithNotification}
                onClick={() => {
                  dispatch(
                    CREATE_OR_UPDATE_ROUTE({
                      withNotification: true,
                      withStatusChange: true,
                      routeId: parseInt(routeId ?? '', 10) ?? null
                    })
                  )
                }}
                icon={faSave}
                label="Speichern und Benachrichtigen"
              />
              {routePdf !== null && (
                <OrderButton
                  onClick={() => {
                    window.open(routePdf ?? '', '_blank')
                  }}
                  icon={faPrint}
                  label="Drucken"
                />
              )}
              {routeId !== null && (
                <OrderButton
                  onClick={() => {
                    navigate(`/routes/${routeId ?? ''}/orders`)
                  }}
                  icon={faClipboardList}
                  label="Route Bestellungen"
                />
              )}
            </div>
            <div className="h-5" />
            <div className="w-[60vw] max-w-[100%] h-[90vh] max-h-[65rem]">
              <RouteMap />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NewRouteScreen
