import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { DELETE } from 'src/consts/methods'
import { SIGN_OUT, SIGN_OUT_FAIL, SIGN_OUT_SUCCESS } from 'src/redux/authReducer'
// import { navigate } from 'src/helpers/navgate'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* SignOutFlow(): Generator<any, void, any> {
  try {
    yield call(apiCall, { endpoint: '/users/sign_out', method: DELETE, params: {} })
    yield put(SIGN_OUT_SUCCESS())
    // navigate('/users/sign_in')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error
    // @ts-ignore
    window.location = '/admin-frontend/users/sign_in'
  } catch (error) {
    yield put(SIGN_OUT_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* SignOutSaga(): Generator<any, void, any> {
  yield takeLatest(SIGN_OUT, SignOutFlow)
}
