/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect } from 'react'
import Layout from '../Layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { FETCH_ORDER } from 'src/redux/orderReducer'
import { useSearchParams } from 'react-router-dom'
import { RootReducer } from 'src/redux/rootReducer'
import { CREATE_INVOICE, CreateInvoicePayload } from 'src/redux/invoiceReducer'
import InvoiceForm from '../molecules/InvoiceForm'

const NewInvoice = (): JSX.Element => {
  const [searchParams] = useSearchParams()
  const orderId = parseInt(searchParams.get('order_id') ?? '', 10)
  const orderData = useSelector((state: RootReducer) => state.orderReducer.orders[orderId])
  const isOrderLoaded = orderData?.order_number.length > 0
  const isLoading = useSelector((state: RootReducer) => state.invoiceReducer.isLoading)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(FETCH_ORDER({ id: orderId }))
  }, [dispatch, orderId])

  if (!isOrderLoaded) {
    return <></>
  }

  const initialFormValues: CreateInvoicePayload = {
    price_gross: orderData.price,
    zip_from: orderData.from_address?.zip ?? '',
    zip_to: orderData.to_address?.zip ?? '',
    company_name: orderData.invoice_address?.name ?? '',
    street: orderData.invoice_address?.street ?? '',
    street_number: orderData.invoice_address?.street_number ?? '',
    zip: orderData.invoice_address?.zip ?? '',
    city: orderData.invoice_address?.city ?? '',
    description: `Transport: ${orderData.mark} ${orderData.bike_model}`,
    order_id: orderId,
    without_tax: false,
    order_number: orderData.order_number,
    payment_method: '',
    transferred_on: '',
    make_payment_gross: orderData.make_payment ? '49' : ''
  }

  return (
    <Layout>
      <div className="font-semibold text-22 p-3">
        <InvoiceForm
          withMakePaymentField={orderData.make_payment}
          initialFormValues={initialFormValues}
          onSubmit={(values: CreateInvoicePayload) => {
            dispatch(CREATE_INVOICE(values))
          }}
          isLoading={isLoading}
        />
      </div>
    </Layout>
  )
}

export default NewInvoice
