import React, { useEffect } from 'react'
import Layout from '../Layout/Layout'
import { useSelector, useDispatch } from 'react-redux'
import { RootReducer } from 'src/redux/rootReducer'
import { Link, useParams } from 'react-router-dom'
import { FETCH_ROUTES_ORDERS } from 'src/redux/routeOrders'
import { FETCH_ROUTE } from 'src/redux/routeReducer'
import getOrderListBorderColor from 'src/helpers/getOrderListBorderColor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import { statusMapper } from 'src/helpers/statusMapper'

const RouteOrders = (): JSX.Element => {
  const { id: route_id } = useParams()
  const dispatch = useDispatch()
  const orders = useSelector((state: RootReducer) => {
    if (typeof route_id === 'string') {
      return state.routeOrdersReducer.orders[route_id] ?? []
    }
    return []
  })

  const routeName = useSelector((state: RootReducer) => {
    return state.routeReducer.routeName
  })

  useEffect(() => {
    if (typeof route_id === 'string') {
      dispatch(FETCH_ROUTES_ORDERS({ route_id }))
    }
  }, [route_id, dispatch])

  useEffect(() => {
    if (typeof route_id === 'string') {
      dispatch(FETCH_ROUTE({ id: parseInt(route_id, 10) }))
    }
  }, [route_id, dispatch])

  return (
    <Layout>
      <div className="bg-white rounded-sm w-full overflow-hidden">
        <p className="text-22 ml-3 mt-3">Routen: {routeName}</p>
        <div className="border-b-4 border-orange" />
        <div className="p-3">
          <table className="w-full text-14">
            <tr>
              <td>Auftragsnummer</td>
              <td>Vorname und Nachname</td>
              <td>Marke</td>
              <td>Status</td>
              <td>Rechnung</td>
            </tr>
            {orders.map((order, idx) => (
              <tr key={order.id} className={`${idx % 2 === 0 ? 'bg-grayBackground' : 'bg-white'} cursor-pointer`}>
                <td className="border border-[#f4f4f4] overflow-hidden relative">
                  <Link
                    to={`/orders/${order.id}`}
                    style={{ borderLeft: `2px solid ${getOrderListBorderColor(order.created_by)}` }}
                    className="p-2 flex-1 h-full">
                    {order.order_number}
                    {order.has_note && (
                      <div className="absolute top-[-10px] right-[-10px] bg-orange w-5 h-5 rotate-45" />
                    )}
                  </Link>
                </td>
                <td className="p-2 border border-[#f4f4f4]">{`${order.first_name} ${order.last_name}`}</td>
                <td className="p-2 border border-[#f4f4f4]">{`${order.mark} ${order.bike_model}`}</td>
                <td className="p-2 border border-[#f4f4f4]">{statusMapper(order.status)}</td>
                <td className="p-2 border border-[#f4f4f4] flex justify-center">
                  {order.invoice_id !== null && (
                    <Link to={`/invoices/${order.invoice_id}`}>
                      <FontAwesomeIcon size="lg" icon={faFileInvoice} />
                    </Link>
                  )}
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </Layout>
  )
}

export default RouteOrders
