import React, { useState } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

interface LinkItem {
  href: string
  icon: IconProp
  label: string
}
interface Props {
  rootHref?: string | undefined
  rootLabel: string
  rootIcon: IconProp
  nestedItems?: LinkItem[]
}

// eslint-disable-next-line no-undefined
const NavigationItem = ({ rootHref = undefined, rootLabel, rootIcon, nestedItems = [] }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    // eslint-disable-next-line no-undefined
    if (rootHref !== undefined) {
      return
    }
    setIsOpen((prev) => !prev)
  }

  return (
    <div>
      <div className={`${isOpen ? 'text-white bg-[black]' : 'text-lightGray'} flex group`}>
        <div className={`group-hover:!bg-orange w-1 ${isOpen ? 'bg-darkBlue' : ''}`} />
        <Link onClick={handleLinkClick} to={rootHref ?? ''} className={'px-2 flex items-center py-3 cursor-pointer'}>
          <FontAwesomeIcon icon={rootIcon} />
          <div className="w-2" />
          <p>{rootLabel}</p>
        </Link>
      </div>
      <div className="relative overflow-hidden">
        <div
          style={{
            maxHeight: isOpen ? `${nestedItems.length * 35}px` : '0px'
          }}
          className={'px-3 text-lightGray bg-[#2c3b41] overflow-hidden transition-all duration-1000'}>
          {nestedItems.map(({ href, label, icon }) => (
            <Link key={href} to={href} className="flex items-center py-1 hover:text-white">
              <FontAwesomeIcon icon={icon} />
              <div className="w-2" />
              <p>{label}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default NavigationItem
