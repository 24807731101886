import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { GET } from 'src/consts/methods'
import { FETCH_USER, FETCH_USER_FAIL, FETCH_USER_SUCCESS } from 'src/redux/userReducer'
import { navigate } from 'src/helpers/navgate'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* FetchUserFlow(): Generator<any, void, any> {
  try {
    const response = yield call(apiCall, { endpoint: '/admin/user', method: GET, params: {} })
    yield put(FETCH_USER_SUCCESS(response))
  } catch (error) {
    yield put(FETCH_USER_FAIL())
    navigate('/users/sign_in')
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* FetchUserSaga(): Generator<any, void, any> {
  yield takeLatest(FETCH_USER, FetchUserFlow)
}
