import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

interface MapMarkerProps {
  color?: string
  markerColor?: string
  number?: number
  zip?: string
}

const MapMarker = ({ color = 'red', markerColor = 'red', number, zip }: MapMarkerProps): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -100%)'
      }}>
      <div className="relative">
        <div className="absolute top-0 left-0 w-full h-full rounded-full flex items-center justify-center">
          <div className="bg-[white] text-[15px] rounded-full px-1 mt-[-7px] font-bold">{number}</div>
        </div>
        <div className="absolute bottom-[-30px] left-0 w-full h-full rounded-full flex items-center justify-center">
          <div className="bg-[white] text-[12px] rounded-md px-1 mt-[-7px] font-bold">{zip}</div>
        </div>
        <div style={{ color: markerColor }}>
          <FontAwesomeIcon size="3x" icon={faMapMarkerAlt} />
        </div>
      </div>
    </div>
  )
}

export default MapMarker
