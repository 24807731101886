import React from 'react'
import { Field } from 'react-final-form'
import InvoiceInput from '../atoms/InvoiceInput'
import { required } from 'src/helpers/required'

const CustomerDataFrom = (): JSX.Element => {
  return (
    <>
      <div>Kundendaten</div>
      <div className="h-3" />
      <div>
        <div>
          <label className="text-16 block">Payment method</label>
          <label htmlFor="cash" className="text-16 block">
            <Field
              validate={required}
              name="payment_method"
              component="input"
              className="mr-1"
              id="cash"
              type="radio"
              value="cash"
            />
            Bar erhalten
          </label>
        </div>

        <label htmlFor="transfer" className="text-16 block">
          <Field
            validate={required}
            name="payment_method"
            component="input"
            className="mr-1"
            id="transfer"
            type="radio"
            value="transfer"
          />
          überwiesen am
        </label>
        <label htmlFor="transfer_instant" className="text-16 block">
          <Field
            validate={required}
            name="payment_method"
            component="input"
            className="mr-1"
            id="transfer_instant"
            type="radio"
            value="transfer_instant"
          />
          Sofort zahlbar per Überweisung
        </label>
      </div>
      <div className="h-3" />
      <div className="flex flex-wrap max-w-[40rem] gap-5">
        <div>
          <label className="text-16 block">Tax number</label>
          <Field name="tax_number" component={InvoiceInput} inputType="text" />
        </div>
        <div>
          <label className="text-16 block">überwiesen am date</label>
          <Field name="transferred_on" component={InvoiceInput} inputType="date" />
        </div>
      </div>
      <div className="h-3" />
      <div>
        <label className="text-16 block">Without tax</label>
        <Field name="without_tax" component={InvoiceInput} inputType="checkbox" />
      </div>
      <div className="h-3" />

      <div className="flex flex-wrap max-w-[40rem] gap-5">
        <div>
          <label className="text-16 block">Leistungsdatum</label>
          <Field name="execution_date" type="date" component={InvoiceInput} inputType="date" />
        </div>
        <div>
          <label className="text-16 block">Company name</label>
          <Field
            validate={required}
            name="company_name"
            component={InvoiceInput}
            inputType="text"
            className="w-[20rem]"
          />
        </div>
        <div className="w-20" />
        <div>
          <label className="text-16 block">Street</label>
          <Field validate={required} name="street" component={InvoiceInput} inputType="text" className="w-[17rem]" />
        </div>
        <div>
          <label className="text-16 block">Street number</label>
          <Field
            validate={required}
            name="street_number"
            component={InvoiceInput}
            inputType="text"
            className="w-[17rem]"
          />
        </div>
        <div>
          <label className="text-16 block">Zip</label>
          <Field validate={required} name="zip" component={InvoiceInput} inputType="number" className="w-[17rem]" />
        </div>
        <div>
          <label className="text-16 block">City</label>
          <Field validate={required} name="city" component={InvoiceInput} inputType="text" className="w-[17rem]" />
        </div>
      </div>
    </>
  )
}

export default CustomerDataFrom
