import React from 'react'
import { AdvancedMarker, Pin } from '@vis.gl/react-google-maps'
import { useSelector, useDispatch } from 'react-redux'
import { RootReducer } from 'src/redux/rootReducer'
import { ACTIVATE_POINT, DEACTIVATE_POINT } from 'src/redux/routeReducer'
import { baseLatLong } from 'src/sagas/FetchDirectionsSaga'
import MapMarker from '../atoms/MapMarker'

const MapMarkers = (): JSX.Element => {
  const points = useSelector((state: RootReducer) => state.routeReducer.points)
  const activePoints = useSelector((state: RootReducer) => state.routeReducer.activePoints)
  const dispatch = useDispatch()
  return (
    <>
      {points.map((point) => (
        <AdvancedMarker
          draggable
          onClick={() => {
            dispatch(ACTIVATE_POINT({ address_id: point.address_id }))
          }}
          key={point.lat + point.lng}
          position={point}>
          <MapMarker
            color={point.transportType === 'start_place' ? 'red' : 'green'}
            markerColor="gray"
            zip={point.zip}
          />
        </AdvancedMarker>
      ))}
      {activePoints.map((point, index) => (
        <AdvancedMarker
          draggable
          onClick={() => {
            dispatch(DEACTIVATE_POINT({ address_id: point.address_id }))
          }}
          key={point.lat + point.lng}
          position={point}>
          <MapMarker
            color={point.transportType === 'start_place' ? 'red' : 'green'}
            markerColor={point.transportType === 'start_place' ? 'red' : 'green'}
            number={index + 1}
            zip={point.zip}
          />
        </AdvancedMarker>
      ))}
      <AdvancedMarker position={baseLatLong}>
        <Pin background="blue" glyphColor={'white'} borderColor={'blue'} />
      </AdvancedMarker>
    </>
  )
}

export default MapMarkers
