import React, { useEffect, useRef, useState } from 'react'
import { RootReducer } from 'src/redux/rootReducer'
import { useSelector } from 'react-redux'
import { APIProvider, Map, MapCameraChangedEvent } from '@vis.gl/react-google-maps'
import MapMarkers from './MapMarkers'

const RouteMap = (): JSX.Element => {
  const [apiLoaded, setApiLoaded] = useState(false)
  const apiKey = useSelector((state: RootReducer) => state.userReducer.google_map_key)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const directions = useSelector((state: RootReducer) => state.routeReducer.directions)
  const directionsRendererRef = useRef<google.maps.DirectionsRenderer | null>(null)

  useEffect(() => {
    if (directionsRendererRef.current === null && apiLoaded) {
      directionsRendererRef.current = new google.maps.DirectionsRenderer({
        suppressMarkers: true
      })
    }
  }, [apiLoaded])

  useEffect(() => {
    if (directions !== null && directionsRendererRef.current !== null) {
      directionsRendererRef.current.setDirections(directions)
      directionsRendererRef.current.setMap(map)
    }
  }, [directions, map])

  if (apiKey === '') {
    return <></>
  }

  return (
    <APIProvider
      apiKey={apiKey}
      onLoad={() => {
        setApiLoaded(true)
      }}>
      {apiLoaded && (
        <Map
          onTilesLoaded={(e) => {
            setMap(e.map)
          }}
          defaultZoom={6}
          mapId="MapId-1"
          defaultCenter={{ lat: 51.165691, lng: 10.451526 }}
          onCameraChanged={(ev: MapCameraChangedEvent) => null}>
          <MapMarkers />
        </Map>
      )}
    </APIProvider>
  )
}

export default RouteMap
