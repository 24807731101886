import React from 'react'
import { Field } from 'react-final-form'
import InvoiceInput from '../atoms/InvoiceInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { required } from 'src/helpers/required'

interface Props {
  name: string
  onRemoveClick: () => void
  index: number
}

const InvoicePositionForm = ({ name, onRemoveClick, index }: Props): JSX.Element => {
  return (
    <div key={name} className="flex gap-2 mb-2 border-b border-depGraphite pb-4 w-fit">
      <div className="flex flex-col items-end justify-end">
        <label>{index + 1}.</label>
      </div>
      <div className="flex flex-col">
        <label>Beschreibung</label>
        <Field name={`${name}.description`} component={InvoiceInput} validate={required} />
      </div>
      <div className="flex flex-col">
        <label>von PLZ</label>
        <Field name={`${name}.zip_from`} component={InvoiceInput} validate={required} />
      </div>
      <div className="flex flex-col">
        <label>nach PLZ</label>
        <Field name={`${name}.zip_to`} component={InvoiceInput} validate={required} />
      </div>
      <div className="flex flex-col">
        <label>Preis Brutto</label>
        <Field name={`${name}.price_gross`} component={InvoiceInput} validate={required} />
      </div>
      <div className="flex items-end justify-end">
        <button type="button" onClick={onRemoveClick}>
          <FontAwesomeIcon className="text-[red]" icon={faTrash} />
        </button>
      </div>
    </div>
  )
}

export default InvoicePositionForm
